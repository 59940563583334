.site {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.page {
  overflow-y: scroll;
}

.wrapper {
  margin: 0 auto;
  max-width: 1440px;
}

.coming-soon {
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}