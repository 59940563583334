@font-face {
    font-family: sabishii-regular;
    src: url(./../assets/fonts/HKGrotesk-Regular.otf);
}

@font-face {
    font-family: sabishii-bold;
    src: url(./../assets/fonts/HKGrotesk-Bold.otf);
}

h1,h2,h3,h4,h5,p {
    font-family: sabishii-regular;
    color: $primary-red;
}

h1 {
    font-size: 4.2rem;
    line-height: 7rem;
    font-family: sabishii-bold;
}

h2 {
    font-size: 3.3rem;
}

h3 {
    font-size: 2.6rem;
}

h4 {
    font-size: 2rem;
}
 
p {
    font-size: 1.6rem;
    line-height: 2rem;
}

h5 {
    font-size: 1.3rem;
    line-height: 1.5rem;
}

a {
    font-family: sabishii-bold;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $primary-red;
    text-decoration: none;
}

.bold {
    font-family: sabishii-bold;
}