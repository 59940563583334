.margin-top-xs {
    margin-top: 5px;
}

.margin-top-s {
    margin-top: 10px;
}

.margin-top-m {
    margin-top: 15px;
}

.margin-top-l {
    margin-top: 30px;
}

.margin-top-xl {
    margin-top: 50px;
}

.margin-top-xxl {
    margin-top: 80px;
}

.margin-bottom-xs {
    margin-bottom: 5px;
}

.margin-bottom-s {
    margin-bottom: 10px;
}

.margin-bottom-m {
    margin-bottom: 15px;
}

.margin-bottom-l {
    margin-bottom: 30px;
}

.margin-bottom-xl {
    margin-bottom: 50px;
}

.margin-bottom-xxl {
    margin-bottom: 80px;
}

.margin-left-xs {
    margin-left: 5px;
}

.margin-left-s {
    margin-left: 10px;
}

.margin-left-m {
    margin-left: 15px;
}

.margin-left-l {
    margin-left: 30px;
}

.margin-left-xl {
    margin-left: 50px;
}

.margin-left-xxl {
    margin-left: 80px;
}

.margin-right-xs {
    margin-right: 5px;
}

.margin-right-s {
    margin-right: 10px;
}

.margin-right-m {
    margin-right: 15px;
}

.margin-right-l {
    margin-right: 30px;
}

.margin-right-xl {
    margin-right: 50px;
}

.margin-right-xxl {
    margin-right: 80px;
}

