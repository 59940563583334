$primary-red: #F06543;
$primary-blue: #0DBCC9;

$secondary-beige: #EDE8CB;
$secondary-navy: #131B38;

.primary-red {
    color: $primary-red;
}

.primary-blue {
    color: $primary-blue;
}

.secondary-beige {
    color: $secondary-beige;
}

.secondary-navy {
    color: $secondary-navy;
}