.padding-top-xs {
    padding-top: 5px;
}

.padding-top-s {
    padding-top: 10px;
}

.padding-top-m {
    padding-top: 15px;
}

.padding-top-l {
    padding-top: 30px;
}

.padding-top-xl {
    padding-top: 50px;
}

.padding-top-xxl {
    padding-top: 80px;
}

.padding-bottom-xs {
    padding-bottom: 5px;
}

.padding-bottom-s {
    padding-bottom: 10px;
}

.padding-bottom-m {
    padding-bottom: 15px;
}

.padding-bottom-l {
    padding-bottom: 30px;
}

.padding-bottom-xl {
    padding-bottom: 50px;
}

.padding-bottom-xxl {
    padding-bottom: 80px;
}

.padding-left-xs {
    padding-left: 5px;
}

.padding-left-s {
    padding-left: 10px;
}

.padding-left-m {
    padding-left: 15px;
}

.padding-left-l {
    padding-left: 30px;
}

.padding-left-xl {
    padding-left: 50px;
}

.padding-left-xxl {
    padding-left: 80px;
}

.padding-right-xs {
    padding-right: 5px;
}

.padding-right-s {
    padding-right: 10px;
}

.padding-right-m {
    padding-right: 15px;
}

.padding-right-l {
    padding-right: 30px;
}

.padding-right-xl {
    padding-right: 50px;
}

.padding-right-xxl {
    padding-right: 80px;
}

